import React from "react";

const PageHeader = ({ title, subtitle, desc }) => (
  <div className="xl:pl-0 mb-6 prose mx-auto text-center">
    {subtitle && (
      <h3 className="text-gradient bg-gradient-to-r from-primary to-primary-dark text-sm uppercase font-black mb-3">
        {subtitle}
      </h3>
    )}
    <h1>{title}</h1>
    {desc && (
      <div className="text-gray-600">
        <p>{desc}</p>
      </div>
    )}
  </div>
);

export default PageHeader;

import React from "react";
import Helmet from "react-helmet";
import SchemaOrg from "./SchemaOrg";

const meta = {
  title: "compAIR - Wettbewerb für autonome Robotik",
  siteUrl: `https://comp-air.at`,
  description: "Wettbewerb für autonome Robotik",
  image: "/fallback.png",
  organization: {
    name: "Verein zu Förderung von Wissenschaft und Technik an Schulen",
    url: "https://comp-air.at",
    logo: "https://comp-air.at/logo.png",
  },
  social: {
    twitter: "@robo4you",
    fbAppID: "",
  },
};

/**
 *
 * @param image JPEG; 1200x630; Quality: 90
 */
const Seo = ({
  title,
  description,
  image,
  url,
  type,
  date,
  section,
  parentSection,
  author,
}) => (
  <div>
    <Helmet>
      {/* General */}
      <title>{title || meta.title}</title>
      <meta name="description" content={description || meta.description} />
      <meta name="image" content={`${meta.siteUrl}${image || meta.image}`} />
      <html lang="de" dir="ltr" prefix="og: http://ogp.me/ns#" />

      {/* I don't know, what this is. Keep it. */}
      <link rel="profile" href="//gmpg.org/xfn/11" />
      <meta
        name="robots"
        content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, viewport-fit=cover"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />

      {/* OpenGraph */}
      <meta property="og:title" content={title || meta.title} />
      {type === "article" && (
        <meta property="og:type" content={type || "website"} />
      )}
      <meta property="og:url" content={`${meta.siteUrl}${url}`} />
      <meta
        property="og:description"
        content={description || meta.description}
      />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:site_name" content="robo4you" />

      <meta
        property="og:image"
        content={`${meta.siteUrl}${image || meta.image}`}
      />
      <meta
        property="og:image:secure_url"
        content={`${meta.siteUrl}${image || meta.image}`}
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      {/* Facebook */}
      <meta property="fb:app_id" content={meta.social.fbAppID} />
      {type === "article" && (
        <meta
          property="article:publisher"
          content="https://facebook.com/robo4you/"
        />
      )}

      {/* Twitter */}
      <meta name="twitter:site" content="@robo4you" />
      <meta name="twitter:creator" content="@robo4you" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={meta.social.twitter} />
      <meta name="twitter:title" content={title || meta.title} />
      <meta
        name="twitter:description"
        content={description || meta.description}
      />
      <meta
        name="twitter:image"
        content={`${meta.siteUrl}${image || meta.image}`}
      />
    </Helmet>
    {/* Schema.org */}
    <SchemaOrg
      title={title || meta.title}
      description={description || meta.description}
      image={`${meta.siteUrl}${image || meta.image}`}
      url={meta.siteUrl + url}
      isArticle={type === "article"}
      publishDate={date}
      section={section}
      parent={parentSection}
    />
  </div>
);

export default Seo;

import React from "react";
import Helmet from "react-helmet";

const SchemaOrg = ({
  image,
  url,
  title,
  description,
  publishDate,
  parent,
  section,
  isArticle,
}) => {
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://robo4you.at/#organization",
        name: "robo4you",
        url: "https://robo4you.at/",
        sameAs: [
          "https://facebook.com/robo4you/",
          "https://github.com/F-WuTS",
          "https://www.youtube.com/channel/UCl_nCdM7TrPDU4VzVcPIgDQ",
          "https://twitter.com/robo4you",
        ],
        logo: {
          "@type": "ImageObject",
          "@id": "https://robo4you.at/#logo",
          url: "https://robo4you.at/logo.png",
          width: 6250,
          height: 1754,
          caption: "robo4you",
        },
        image: { "@id": "https://robo4you.at/#logo" },
      },
      {
        "@type": "WebSite",
        "@id": "https://robo4you.at/#website",
        url: "https://robo4you.at/",
        name: "robo4you",
        description: "Robotik. Bildung. Innovation",
        publisher: {
          "@id": "https://robo4you.at/#organization",
        },
      },
      {
        "@type": "ImageObject",
        "@id": `${url}#primaryimage`,
        url: image,
        width: 1200,
        height: 630,
      },
      {
        "@type": "WebPage",
        "@id": `${url}#webpage`,
        url: url,
        inLanguage: "de-DE",
        name: title,
        isPartOf: {
          "@id": "https://robo4you.at/#website",
        },
        primaryImageOfPage: {
          "@id": `${url}#primaryimage`,
        },
        datePublished: publishDate,
        dateModified: new Date().toISOString(),
        description: description,
        breadcrumb: {
          "@id": `${url}#breadcrumb`,
        },
      },
    ],
  };

  return (
    <Helmet>
      {/* Schema.org tags */}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default SchemaOrg;
